"use client";
import React, { useContext } from "react";
import styles from "./ChatCard.module.scss";
import Image from "next/image";
import { Images } from "@/utils/constants/imageRelativePaths";
import { CurrencyType, RoomResponse } from "@/types/common.types";
import { AppContext, chatMemberProfile } from "@/context/AppContext";
import { sendChatInvite } from "@/services/chat.service";
import { useRouter } from "next/navigation";

const ChatCard = (props: {
  session: RoomResponse;
  handleClick?: () => void;
  index: number;
}) => {
  const totalStars = 5;
  const router = useRouter();
  const { liveRoom, userProfile } = props.session;
  const context = useContext(AppContext);
  const {
    paidDmSocket,
    setChatData,
    userData,
    auth,
    chatData,
    setPopups,
    walletBalance,
  } = context ?? {};
  const { token } = auth ?? {};

  const handleBtnClick = () => {
    if (!token) {
      setPopups &&
        setPopups({
          showLoginPopup: true,
        });
      return;
    }

    if (
      (userData?.diamonds ?? 0) <= props.session.userProfile.rateInInr &&
      (walletBalance?.astroVCard ?? 0) === 0
    ) {
      setChatData &&
        setChatData({ ...chatData, isInsufficientBalancePopup: true });

      return;
    }

    userData &&
      setChatData &&
      setChatData({
        initiator: {
          attributes: {
            isOnline: true,
          },
          user: userData as unknown as chatMemberProfile,
        },
        receiver: {
          attributes: {},
          user: userProfile as chatMemberProfile,
        },
        currencyType:
          (walletBalance?.astroVCard ?? 0) > 0
            ? CurrencyType.ASTROVCARDS
            : CurrencyType.DIAMONDS,
      });

    return router.push(
      `/chat-intake-form?receiverUserId=${userProfile?.userId}`
    );
  };
  return (
    <div className={styles.ListCard} onClick={props.handleClick}>
      <div className={styles.leftContent}>
        <div className={styles.imageWrapper}>
          <Image
            src={userProfile?.photo}
            alt="some_img"
            height={87}
            width={87}
            className={styles.profileImage}
          />
          <span className={styles.status}>Online</span>
        </div>

        <div className={styles.stars}>
          {Array.from({ length: totalStars }, (_, index) => (
            <Image
              key={index}
              src={
                index < Number(userProfile?.rating)
                  ? Images.starFilled
                  : Images.starUnfilled
              }
              alt={"star"}
              width={16}
              height={16}
            />
          ))}
        </div>

        <span className={styles.order}>(120 Orders)</span>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.content}>
          <h1>{userProfile?.name}</h1>
          <div className={styles.categoryWrapper}>
            {liveRoom?.creatorSubCategories?.map((subCat) => (
              <span className={styles.category}>{subCat}</span>
            ))}
          </div>
          <ul>
            {liveRoom?.languages?.map((lang, index) => (
              <li key={index}>{lang}</li>
            ))}
          </ul>
          <p>Exp: {userProfile?.experience} Yrs</p>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <span className={styles.price}>
          <div
            className={`${styles.price_cart} ${
              (walletBalance?.astroVCard ?? 0) > 0 ? styles.old_price : ""
            }`}
          >
            <b>₹{userProfile?.rateInInr}</b> / min
            <hr className={styles.line} />
          </div>
          {(walletBalance?.astroVCard ?? 0) > 0 && (
            <div className={styles.free_price_cart}>
              <b>Free</b>
            </div>
          )}
        </span>
        <button
          className={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            handleBtnClick();
          }}
        >
          {" "}
          <Image src={Images.chat} alt="chat" width={30} height={30} />
          <Image src={Images.chatPurple} alt="chat" width={14} height={14} />
          Chat
        </button>
      </div>
    </div>
  );
};

export default ChatCard;
